import {
  soybeanVarieties,
  wheatVarieties,
} from '@/pages/cultivares/mock/varietySeeds.js'
import { SOYBEAN_ID, WHEAT_ID } from '@/utils/crops'
import { mockFlag } from '@/utils/mock'
import { APIFailureWrapper } from '@/utils/mock'

const varietyRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/sementes/fazenda/:id',
      result: ({ queryParams }) => {
        let products = []
        if (queryParams.crop_id == SOYBEAN_ID) {
          products = soybeanVarieties
        } else if (queryParams.crop_id == WHEAT_ID) {
          products = wheatVarieties
        }
        return APIFailureWrapper({
          content: products,
          errorMessage: 'Erro ao listar as cultivares',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/varieties',
      result: ({ requestBody }) => {
        const varieties = [...soybeanVarieties, ...wheatVarieties]
        const responseObj = JSON.parse(requestBody)
        varieties.forEach((variety) => {
          if (variety.semente_id == responseObj.seedId) variety.added = true
        })
        return APIFailureWrapper({
          content: Number(responseObj.seedId),
          errorMessage: 'Erro ao adicionar a cultivar',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'delete',
      url: '/cultivares/:varietyId/fazenda/:farmId',
      result: ({ queryParams }) => {
        const varieties = [...soybeanVarieties, ...wheatVarieties]
        varieties.forEach((variety) => {
          if (variety.semente_id == queryParams.varietyId) variety.added = false
        })
        return APIFailureWrapper({
          content: [],
          errorMessage: 'Erro ao deletar a cultivar',
        })
      },
    },
    'on'
  ),
]

export { varietyRoutes }
