<script>
export default {
  props: {
    title: { type: String, default: '' },
    text: { type: String, default: '' },
    dialog: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({
        yes: {
          l: 'Sim',
          c: 'green',
        },
        no: {
          l: 'Não',
          c: 'red',
        },
      }),
    },
  },
  methods: {
    agree() {
      this.$emit('agree')
      return true
    },
    disagree() {
      this.$emit('disagree')
      return false
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>
        <v-card-text>{{ text }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :color="options.no.c" text @click="disagree">{{
            options.no.l
          }}</v-btn>
          <v-btn :color="options.yes.c" text @click="agree">{{
            options.yes.l
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
