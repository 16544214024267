<script>
export default {
  props: {
    plot: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      polygon: {
        polygonOptions: {
          strokeColor: '#1976d2',
          fillColor: '#1976d2',
        },
      },
    }
  },

  computed: {
    paths() {
      if (!this.plot.coordenadas_lat || !this.plot.coordenadas_lng) {
        return []
      }

      let latitudes = this.plot.coordenadas_lat.split('|')
      let longitudes = this.plot.coordenadas_lng.split('|')

      return latitudes.map((lat, index) => ({
        lat: parseFloat(lat),
        lng: parseFloat(longitudes[index]),
      }))
    },
  },
}
</script>

<template>
  <gmap-polygon
    :paths="paths"
    :editable="false"
    :options="polygon.polygonOptions"
  >
  </gmap-polygon>
</template>
