<template>
  <v-dialog :value="dialog" width="560" persistent @keydown.esc="close">
    <template #activator="{ on }">
      <slot name="activator" :on="on" />
    </template>
    <v-card class="card-style">
      <v-toolbar flat>
        <v-toolbar-title class="title-header">
          {{ $t('season_report.insert_harvest_data_title') }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon color="gray" @click="close" right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="semeadura && colheita" class="harvest-register">
        <v-row no-gutters class="title-description mt-5">
          <template>
            <v-col cols="12" md="6" class="text-start pl-1">
              {{ $t('season_report.bags_per_hectare') }}
            </v-col>
            <v-col cols="6" class="text-start pl-4 d-none d-sm-none d-md-flex">
              {{ $t('season_report.harvest_date') }}?
            </v-col>
          </template>
        </v-row>
        <v-row no-guttes class="mt-n2">
          <v-col cols="12" sm="12" md="6" lg="6">
            <converter-field
              v-model="colheita.produtividade"
              :errors="getErrorsHarvest(colheita)"
              :disabled="saving"
              unit-measure="performance"
              :key="$currentLanguage()"
              outlined
              dense
              background-color="white"
              color="#39af49"
              class="custom-label"
            >
              <template #append>
                <font-awesome-icon icon="seedling" />
              </template>
            </converter-field>
          </v-col>

          <v-col cols="12" sm="12" md="6" lg="6">
            <v-menu
              ref="menu_colheita"
              v-model="menu_colheita"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="data_colheita_formatted"
                  :label="$t('colheita.data_colheita')"
                  readonly
                  solo
                  dense
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :error-messages="getErrorsDate(colheita)"
                  :disabled="saving"
                  color="#39af49"
                >
                  <template #append>
                    <font-awesome-icon color="#788476" icon="calendar" />
                  </template>
                </v-text-field>
              </template>

              <v-date-picker
                v-model="colheita.data_colheita"
                :allowed-dates="restrictedDates"
                close-on-content-click
                :return-value.sync="colheita.data_colheita"
                :locale="getLocale"
                :min="minimumHarvestDate"
                show-adjacent-months
                color="#39af49"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          class="title-description text--none d-flex align-center"
        >
          <v-col cols="8" sm="10" class="mt-n1"
            >{{ $t('harvest.did_desiccation') }}?
          </v-col>
          <v-col cols="4" sm="2">
            <v-switch
              v-model="colheita.dessecacao"
              inset
              :label="colheita.dessecacao ? $t('yes') : $t('no')"
              dense
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
        <v-divider color="grey darken-3" class="divider-point" dark></v-divider>
        <v-row no-gutters>
          <v-col>
            <v-checkbox
              class="pl-2 checkbox-lost-harvest mt-n1"
              color="#39af49"
              hide-details
              v-model="colheita.lost"
              :label="$t('harvest.lost_harvest')"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="distance-buttons">
        <v-btn
          v-if="semeadura && semeadura.colheita"
          class="gray--text text-none button-remove-and-close"
          @click="excluir"
          :disabled="saving"
          :loading="deleting"
        >
          <span class="text-cancel-and-remove">{{
            $t('colheita.remover_colheita')
          }}</span>
          <v-icon color="red darken-1" class="icon-button" small
            >mdi-delete</v-icon
          ></v-btn
        >
        <v-btn
          v-if="semeadura && !semeadura.colheita"
          class="text-none button-remove-and-close"
          @click="close"
          :disabled="saving || deleting"
          ><span class="text-cancel-and-remove">{{ $t('cancelar') }}</span>
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn
          class="white--text text-none button-register-confirm"
          text
          @click="save"
          :loading="saving"
          :disabled="deleting"
          >{{ $t('register.register')
          }}<v-icon class="icon-button" small>mdi-check</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { validationMixin } from 'vuelidate'
import { notEmpty, notNull } from '@/components/Form/validations'
import { currentLocale } from '@/language'
import ConverterField from '@/components/Form/ConverterField'
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'ModalColheita',

  props: {
    semeadura: { type: Object, default: null },
    minimumHarvestDate: { type: String, default: null },
    dialog: { type: Boolean, default: false },
  },

  components: {
    ConverterField,
  },

  mixins: [validationMixin],
  validations() {
    return {
      colheita: {
        produtividade: { required: this.colheita?.lost ? false : notEmpty() },
        data_colheita: { required: this.colheita?.lost ? false : notNull() },
      },
    }
  },

  data() {
    return {
      menu_colheita: false,
      colheita: null,
      saving: false,
      deleting: false,
      trySubmit: false,
    }
  },

  watch: {
    dialog: {
      handler(isOpened) {
        if (!isOpened) {
          return
        }
        this.open()
      },
      immediate: true,
    },
  },

  computed: {
    getLocale() {
      return currentLocale()
    },
    data_colheita_formatted() {
      return this.colheita.data_colheita
        ? this.colheita.data_colheita.formatDate()
        : null
    },
  },

  methods: {
    restrictedDates(date) {
      return moment().isAfter(date)
    },
    open(sowing = null) {
      this.colheita = {
        id: null,
        semeadura_id: sowing ? sowing.id : this.semeadura?.id,
        data_colheita: null,
        produtividade: null,
        dessecacao: null,
        lost: false,
      }
      if (this.semeadura.colheita)
        this.colheita = Object.clone(this.semeadura.colheita)
    },
    close() {
      this.$emit('close')
    },
    async save() {
      this.$v.$touch()
      this.trySubmit = true
      if (this.validateInputs()) {
        return
      }
      const colheita = {
        id: this.colheita.id,
        semeadura_id: this.colheita.semeadura_id,
        data_colheita: this.colheita.data_colheita,
        produtividade: this.colheita.produtividade,
        dessecacao: this.colheita.dessecacao,
        lost: this.colheita.lost,
      }

      this.saving = true

      try {
        const { create, update } = this.$api.semeaduras.colheita

        const action = colheita.id ? update : create

        await action(colheita)

        this.$root.$emit('notify', 'success', this.$t('succesfuly_saved'))

        this.$emit('actionFinalized')

        this.saving = false

        logEvent(events.harvests.clickedButton_saveSingleHarvest)
        this.close()
      } catch (e) {
        this.saving = false
        this.close()

        throw Error(e)
      }
    },
    validateInputs() {
      if (!this.colheita.lost) {
        if (this.$v.$invalid || this.colheita.data_colheita === '') {
          this.colheita.produtividade = 0
          this.colheita.data_colheita = ''
          return true
        }
      }
      return false
    },
    async excluir() {
      this.deleting = true

      try {
        await this.$api.semeaduras.colheita.delete(this.semeadura.colheita.id)

        this.$root.$emit(
          'notify',
          'success',
          this.$t('colheita.excluida_sucesso')
        )

        this.$emit('actionFinalized')
        this.close()
      } catch {
        this.$root.$emit('notify', 'error', 'Erro ao excluir')
      }

      this.deleting = false
    },
    getErrorsDate(harvest) {
      const harvestDate = harvest?.data_colheita === ''

      if (harvestDate && !harvest?.lost) {
        return [this.$t('required')]
      }
      return []
    },
    getErrorsHarvest(harvest) {
      const harvestProductivity =
        harvest?.produtividade === 0 || harvest?.produtividade === undefined

      if (harvestProductivity && !harvest?.lost) {
        return [this.$t('required')]
      }
      return []
    },
  },
}
</script>

<style scoped>
::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: -10px;
}
::v-deep
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none !important;
}
::v-deep
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot {
  border-radius: 8px;
}

::v-deep .v-input__append-inner {
  margin-top: 0px !important;
}

::v-deep .theme--light.v-input {
  color: #788476;
}

::v-deep .v-text-field.v-text-field--solo .v-input__control input {
  color: #5b6459;
}
::v-deep .title-description {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #253521;
}
::v-deep.v-input--selection-controls .v-input__slot > .v-label {
  color: #0e180c;
}

.card-style {
  border-radius: 8px;
}

.checkbox-lost-harvest {
  background: #e6e9e6;
  border-radius: 8px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 8px !important;
}

.divider-point {
  margin: 10px;
  margin-bottom: 15px;
}

.distance-buttons {
  padding: 10px 20px;
}

.title-header {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  color: #1a2b46;
}

.harvest-register {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 500px;
  padding: 21px 42px;
  background: #f5f5f5 !important;
}

.button-remove-and-close {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  background: #fff !important;
}

.button-register-confirm {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  background-color: #39af49 !important;
  border-color: #39af49;
}

.icon-button {
  padding-top: 2px;
  padding-left: 8px;
}

.text-cancel-and-remove {
  color: #3f4e3c;
}
</style>
