<template>
  <div>
    <v-card>
      <v-card
        :class="[talhao.enabled ? 'green center' : 'card-disabled']"
        height="176px"
      >
        <v-card-text
          class="float-right text-right d-block card-text-position pa-2"
        >
          <div class="white--text font-weight-black">{{ $t('area') }}</div>
          <div class="white--text font-weight-black">
            {{ areaTalhao }} {{ $unitMeasures['area'][$currentLanguage()] }}
          </div>
        </v-card-text>
      </v-card>

      <v-card height="124px">
        <v-card-text class="text-left pb-0 font-weight-black" flat>
          <v-row no-gutters class="text-left pb-0 font-weight-black">
            <v-col cols="6">
              <p class="size-plot-name" :title="talhao.nome">
                {{ talhao.nome }}
              </p>
            </v-col>
            <v-col v-if="!talhao.enabled" cols="6" class="line-disable">
              <span class="text-disable">{{ $t('he_disabled') }}</span>
            </v-col>
          </v-row>
          <div class="caption text-wrap">lat: {{ talhao.latitude }}</div>
          <div class="caption text-wrap">long: {{ talhao.longitude }}</div>
        </v-card-text>
        <v-card-actions
          :class="[
            talhao.enable
              ? 'd-flex justify-space-around'
              : 'd-flex justify-end',
          ]"
        >
          <v-btn
            v-if="talhao.enabled"
            small
            class="ml-1"
            :class="[!talhao.ndviEnabled ? 'mr-auto' : '']"
            @click="editPlot"
          >
            <span
              v-if="
                !talhao.ndviEnabled &&
                $vuetify.breakpoint.md &&
                !$vuetify.breakpoint.sm
              "
              ><v-icon small>mdi-pencil</v-icon></span
            >
            <span v-else>{{ $t('editar') }}</span>
          </v-btn>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  v-if="farm.enabled"
                  class="button-enable-disable"
                  @click="plotEnableDisable(talhao)"
                  small
                  disabled
                >
                  <v-icon v-if="talhao.enabled"
                    >mdi-toggle-switch-off-outline</v-icon
                  >
                  <v-icon v-else class="icon-enable">mdi-toggle-switch</v-icon>
                  <span
                    v-if="talhao.enabled"
                    class="black--text text-none pl-1"
                  >
                    <span
                      v-if="
                        !talhao.ndviEnabled &&
                        !$vuetify.breakpoint.md &&
                        !$vuetify.breakpoint.lg
                      "
                      >{{ $t('disable') }}</span
                    ></span
                  >
                  <span v-else class="black--text text-none pl-1">
                    {{ $t('enable') }}
                  </span>
                </v-btn>
              </div>
            </template>
            <span> {{ $t('function_temporarily_unavailable') }} </span>
          </v-tooltip>

          <v-badge
            overlap
            dot
            v-if="talhao.ndviEnabled && talhao.enabled"
            color="amber darken-2"
            class="mr-auto"
          >
            <v-btn @click="openAnalyze" small class="ml-3">
              <span
                v-if="
                  !talhao.ndviEnabled &&
                  $vuetify.breakpoint.md &&
                  !$vuetify.breakpoint.sm
                "
                ><v-icon small>mdi-map-marker-path</v-icon></span
              >
              <span v-else>NDVI</span>
            </v-btn>
          </v-badge>

          <v-btn
            @click="confirmar"
            color="red"
            x-small
            icon
            text
            v-if="hasPermission($p.plot.delete)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>

    <dialog-confirmation
      v-if="showConfirmationDialog"
      ref="dialogConfirmation"
      @agree="excluir"
      :dialog="showConfirmationDialog"
      :title="$t('talhoes.excluir_title')"
      :text="$t('talhoes.excluir_text')"
    />

    <modal-ndvi ref="modalNdvi"></modal-ndvi>

    <plot-register
      v-if="plotRegisterOpen && talhao.id && accountsPlotsArea >= 0"
      :plot="talhao"
      :plots="plots"
      :accounts-plots-area="accountsPlotsArea"
      @refresh="refreshParent"
    ></plot-register>

    <action-dialog
      :dialog="dialogEnable"
      :text="$t('fazenda.enable.warning', [countEnabledAsc])"
      :text-cancel="$t('no')"
      :text-confirm="$t('yes')"
      :cancel="closeEnableConfirmation"
      :confirm="plotEnable"
      color-cancel="#8f8f8f"
      color-confirm="#39af49"
    />

    <action-dialog
      :dialog="dialogEnableLimitExceeded"
      :text="$t('fazenda.enable.error')"
      :text-cancel="$t('cancelar')"
      :text-confirm="$t('get_in_touch')"
      :cancel="closeDialogEnableExceededConfirmation"
      :confirm="getInTouch"
      color-cancel="#8f8f8f"
      color-confirm="#39af49"
    />

    <action-dialog
      :dialog="dialogDisable"
      :text="$t('fazenda.disable.warning')"
      :text-cancel="$t('no')"
      :text-confirm="$t('yes')"
      :cancel="closeDisableConfirmation"
      :confirm="plotEnable"
      color-cancel="#8f8f8f"
      color-confirm="#39af49"
    />
  </div>
</template>

<script>
import DialogConfirmation from '@/pages/fazendas/DialogConfirmation.vue'
import ModalNdvi from '@/pages/ndvi/ModalNdvi.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import ActionDialog from '@/components/Dialogs/ActionConfirmationDialog.vue'
import PlotRegister from '@/pages/fazendas/ModalMaps.vue'
import { logEvent, events } from '@/services/analytics'
import { mapActions, mapGetters } from 'vuex'
import { convertToTargetUnit } from '@/components/Form/converter'

export default {
  name: 'ModalTalhoesItem',

  mixins: [PermissionMixin],

  components: {
    PlotRegister,
    DialogConfirmation,
    ModalNdvi,
    ActionDialog,
  },

  data() {
    return {
      countEnabled: null,
      enabled: null,
      seasonAccount: null,
      plotRegisterOpen: false,
      dialogEnable: false,
      dialogEnableLimitExceeded: false,
      dialogDisable: false,
      showConfirmationDialog: false,
    }
  },

  props: {
    talhao: {
      type: Object,
      required: true,
    },
    plots: {
      type: Array,
      default: () => [],
    },
    accountsPlotsArea: {
      type: Number,
      default: null,
    },
    farm: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('user', ['currentUser']),
    areaTalhao() {
      return convertToTargetUnit(this.talhao.area, 'area')
    },
    countEnabledAsc() {
      return this.countEnabled == 0 ? 1 : this.countEnabled + 1
    },
    accountName() {
      return this.currentUser?.name
    },
    contactWhatsapp() {
      return (
        process.env.VUE_APP_RELATIONSHIP_WHATSAPP +
        this.$t('contato.msg_whatsapp_limit_enable_exceeded', [
          this.accountName,
        ])
      )
    },
  },

  methods: {
    ...mapActions('farms', ['enableDisableFarm']),
    ...mapActions('plot', ['deletePlot', 'enableOrDisablePlot']),
    async getSeasonAccount() {
      const response = await this.$api.seasonAccount?.get()
      this.seasonAccount = await response.data
      this.countEnabled = this.seasonAccount?.count_enabled
    },
    getInTouch() {
      window.open(this.contactWhatsapp).focus()
      this.dialogEnableLimitExceeded = false
    },
    editPlot() {
      this.plotRegisterOpen = true
    },
    refreshParent() {
      this.plotRegisterOpen = false
      this.$emit('refresh')
    },
    confirmar() {
      this.showConfirmationDialog = true
    },
    openAnalyze() {
      this.$refs.modalNdvi.open(this.talhao)
      logEvent(events.ndvi.open, {
        plot_id: this.talhao.id,
        plotArea: this.talhao.area,
      })
    },
    closeEnableConfirmation() {
      this.dialogEnable = false
      this.plotId = null
    },
    closeDisableConfirmation() {
      this.dialogDisable = false
      this.plotId = null
    },
    showEnableDisableConfirmation(plotId) {
      this.plotId = plotId
      this.dialogEnable = this.countEnabled < 2 ? true : false
    },
    showDisableConfirmation(plotId) {
      this.plotId = plotId
      this.dialogDisable = true
    },
    showDialogEnableExceededConfirmation() {
      this.dialogEnable = false
      this.dialogEnableLimitExceeded = true
    },
    closeDialogEnableExceededConfirmation() {
      this.dialogEnable = false
      this.dialogEnableLimitExceeded = false
    },
    async plotEnable() {
      this.setEnableAndCountDisablePlot(this.plotId)

      await this.enableOrDisablePlot(this.plotId)
      await this.getSeasonAccount()

      if (this.enabled) {
        this.$root.$emit(
          'notify',
          'success',
          this.$t('talhoes.disable.success')
        )
        this.closeEnableConfirmation()
        this.closeDisableConfirmation()
        this.$emit('refresh')
        return
      }

      this.$root.$emit('notify', 'success', this.$t('fazenda.enable.success'))
      this.closeEnableConfirmation()
      this.closeDisableConfirmation()
      this.$emit('refresh')
    },
    async plotEnableDisable(plot) {
      if (!plot.enabled) {
        this.showEnableDisableConfirmation(plot.id)
      } else {
        this.showDisableConfirmation(plot.id)
      }

      this.setEnableAndCountDisablePlot(plot.id)
      await this.getSeasonAccount()

      if (this.countEnabled >= 2 && !this.enabled) {
        this.showDialogEnableExceededConfirmation()
      }
    },
    setEnableAndCountDisablePlot(plotId) {
      const plot = this.plots?.find((plot) => plot.id === plotId)
      this.getSeasonAccount()

      this.enabled = plot?.enabled
    },
    async excluir() {
      this.showConfirmationDialog = false
      await this.deletePlot(this.talhao.id)
      this.$emit('refresh')
    },
  },
}
</script>

<style lang="scss" scoped>
.v-icon .notranslate .mdi .mdi-dots-vertical {
  color: grey;
}

.menu-criar-talhao {
  font-size: 20px;
}

.size-plot-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.menu-criar-talhao-text {
  font-size: 12px;
}
.card-disabled {
  height: 176px;
  background-color: #9e9e9e !important;
}

.icon-enable {
  color: #62cc70 !important;
}

.text-disable {
  background: #9e9e9e;
  border-radius: 8px;
  color: #fff;
  padding-top: 3px;
}

.line-disable {
  display: flex;
  justify-content: flex-end;
}

.button-enable-disable {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding-left: 8px;
  background-color: #fff !important;
}
</style>
