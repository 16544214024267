const soybeanVarieties = [
  {
    id: 1750,
    safra_id: 8,
    semente_id: 1750,
    regiao_sojicola: '101',
    vcu: null,
    estado_id: null,
    recomendado: 0,
    ciclo: null,
    populacao_min: null,
    populacao_max: null,
    semeadura_ini: null,
    semeadura_fim: null,
    created_at: '2022-08-04 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: '2607 XTD',
    titular: null,
    grupo: '6',
    ogm: 'Sim',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 1,
    inox: 0,
    crop_cycle_id: null,
    growth_habit_id: 2,
    asset_owner_id: 200,
    crop_name: 'Soybean',
    ciclo_dias: 131,
    asset_owner_name: 'Basf',
    added: false,
    cultivar_id: 11888,
  },
  {
    id: 1227,
    safra_id: 8,
    semente_id: 1227,
    regiao_sojicola: '101',
    vcu: null,
    estado_id: null,
    recomendado: 1,
    ciclo: null,
    populacao_min: 200,
    populacao_max: 300,
    semeadura_ini: '2024-09-20',
    semeadura_fim: '2024-12-31',
    created_at: '2019-10-10 12:12:01',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: '5D6215 IPRO',
    titular: null,
    grupo: '6.4',
    ogm: 'Sim',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 1,
    inox: 0,
    crop_cycle_id: null,
    growth_habit_id: 2,
    asset_owner_id: 214,
    crop_name: 'Soybean',
    ciclo_dias: 132,
    asset_owner_name: 'Brevant',
    added: false,
    cultivar_id: 13102,
  },
  {
    id: 263,
    safra_id: 8,
    semente_id: 263,
    regiao_sojicola: '101',
    vcu: null,
    estado_id: null,
    recomendado: 1,
    ciclo: null,
    populacao_min: 220,
    populacao_max: 300,
    semeadura_ini: '2024-10-01',
    semeadura_fim: '2024-12-15',
    created_at: null,
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: '5D634 RR',
    titular: null,
    grupo: '6.3',
    ogm: 'Sim',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 1,
    inox: 0,
    crop_cycle_id: null,
    growth_habit_id: 2,
    asset_owner_id: 189,
    crop_name: 'Soybean',
    ciclo_dias: 136,
    asset_owner_name: 'Dow AgroSciences',
    added: false,
    cultivar_id: 12306,
  },
  {
    id: 1271,
    safra_id: 8,
    semente_id: 1271,
    regiao_sojicola: '101',
    vcu: null,
    estado_id: null,
    recomendado: 0,
    ciclo: null,
    populacao_min: null,
    populacao_max: null,
    semeadura_ini: null,
    semeadura_fim: null,
    created_at: '2020-10-01 12:43:56',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: '5G8015 IPRO',
    titular: null,
    grupo: '8.0',
    ogm: 'Sim',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 1,
    inox: 0,
    crop_cycle_id: null,
    growth_habit_id: 1,
    asset_owner_id: 214,
    crop_name: 'Soybean',
    ciclo_dias: 142,
    asset_owner_name: 'Brevant',
    added: true,
    cultivar_id: 14443,
  },
  {
    id: 1310,
    safra_id: 8,
    semente_id: 1310,
    regiao_sojicola: '101',
    vcu: null,
    estado_id: null,
    recomendado: 0,
    ciclo: null,
    populacao_min: null,
    populacao_max: null,
    semeadura_ini: null,
    semeadura_fim: null,
    created_at: '2020-11-16 13:22:55',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: '62MS00 RR',
    titular: null,
    grupo: '6.2',
    ogm: 'Sim',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 1,
    inox: 0,
    crop_cycle_id: null,
    growth_habit_id: 2,
    asset_owner_id: 40,
    crop_name: 'Soybean',
    ciclo_dias: 132,
    asset_owner_name: 'Macro Seed',
  },
  {
    id: 3392,
    safra_id: 8,
    semente_id: 3392,
    regiao_sojicola: '101',
    vcu: null,
    estado_id: null,
    recomendado: 0,
    ciclo: null,
    populacao_min: null,
    populacao_max: null,
    semeadura_ini: null,
    semeadura_fim: null,
    created_at: '2024-06-12 17:32:16',
    updated_at: '2024-06-12 17:32:16',
    deleted_at: null,
    cultivar: '66E',
    titular: null,
    grupo: '6.6',
    ogm: 'Sim',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 1,
    inox: 0,
    crop_cycle_id: null,
    growth_habit_id: 2,
    asset_owner_id: 115,
    crop_name: 'Soybean',
    ciclo_dias: 135,
    asset_owner_name: 'Neogen',
  },
  {
    id: 1571,
    safra_id: 8,
    semente_id: 1571,
    regiao_sojicola: '101',
    vcu: null,
    estado_id: null,
    recomendado: 0,
    ciclo: null,
    populacao_min: null,
    populacao_max: null,
    semeadura_ini: null,
    semeadura_fim: null,
    created_at: '2021-10-26 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: '71MF00 RR',
    titular: null,
    grupo: '7.1',
    ogm: 'Sim',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 1,
    inox: 0,
    crop_cycle_id: null,
    growth_habit_id: 2,
    asset_owner_id: 40,
    crop_name: 'Soybean',
    ciclo_dias: 138,
    asset_owner_name: 'Macro Seed',
  },
  {
    id: 1849,
    safra_id: 8,
    semente_id: 1849,
    regiao_sojicola: '101',
    vcu: null,
    estado_id: null,
    recomendado: 1,
    ciclo: null,
    populacao_min: 340,
    populacao_max: 360,
    semeadura_ini: '2024-10-01',
    semeadura_fim: '2024-12-15',
    created_at: '2022-12-27 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: '95R21 E',
    titular: null,
    grupo: '5.2',
    ogm: 'Sim',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 1,
    inox: 0,
    crop_cycle_id: null,
    growth_habit_id: 2,
    asset_owner_id: 136,
    crop_name: 'Soybean',
    ciclo_dias: 115,
    asset_owner_name: 'Pioneer',
    added: false,
    cultivar_id: 13104,
  },
  {
    id: 1343,
    safra_id: 8,
    semente_id: 1343,
    regiao_sojicola: '101',
    vcu: null,
    estado_id: null,
    recomendado: 1,
    ciclo: null,
    populacao_min: 280,
    populacao_max: 300,
    semeadura_ini: '2024-09-20',
    semeadura_fim: '2024-12-10',
    created_at: '2021-04-19 13:18:50',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: '95R30 IPRO',
    titular: null,
    grupo: '5.0',
    ogm: 'Sim',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 1,
    inox: 0,
    crop_cycle_id: null,
    growth_habit_id: 2,
    asset_owner_id: 136,
    crop_name: 'Soybean',
    ciclo_dias: 123,
    asset_owner_name: 'Pioneer',
    added: true,
    cultivar_id: 13103,
  },
  {
    id: 1307,
    safra_id: 8,
    semente_id: 1307,
    regiao_sojicola: '101',
    vcu: null,
    estado_id: null,
    recomendado: 1,
    ciclo: null,
    populacao_min: 250,
    populacao_max: 350,
    semeadura_ini: '2024-10-01',
    semeadura_fim: '2024-12-15',
    created_at: '2020-11-09 13:43:34',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: '95R40 IPRO',
    titular: null,
    grupo: '5.4',
    ogm: 'Sim',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 1,
    inox: 0,
    crop_cycle_id: null,
    growth_habit_id: 2,
    asset_owner_id: 136,
    crop_name: 'Soybean',
    ciclo_dias: 122,
    asset_owner_name: 'Pioneer',
    added: true,
    cultivar_id: 14170,
  },
  {
    id: 11,
    safra_id: 8,
    semente_id: 11,
    regiao_sojicola: '101',
    vcu: null,
    estado_id: null,
    recomendado: 1,
    ciclo: null,
    populacao_min: 320,
    populacao_max: 390,
    semeadura_ini: '2024-09-15',
    semeadura_fim: '2024-12-15',
    created_at: null,
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: '95R51',
    titular: null,
    grupo: '5.3',
    ogm: 'Sim',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 1,
    inox: 0,
    crop_cycle_id: null,
    growth_habit_id: 2,
    asset_owner_id: 136,
    crop_name: 'Soybean',
    ciclo_dias: 120,
    asset_owner_name: 'Pioneer',
  },
  {
    id: 3332,
    safra_id: 8,
    semente_id: 3332,
    regiao_sojicola: '101',
    vcu: null,
    estado_id: null,
    recomendado: 1,
    ciclo: null,
    populacao_min: 220,
    populacao_max: 260,
    semeadura_ini: '2024-09-15',
    semeadura_fim: '2024-12-15',
    created_at: '2024-04-17 17:07:44',
    updated_at: '2024-04-17 17:07:44',
    deleted_at: null,
    cultivar: '95R70 CE',
    titular: null,
    grupo: '5.7',
    ogm: 'Sim',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 1,
    inox: 0,
    crop_cycle_id: null,
    growth_habit_id: 2,
    asset_owner_id: 136,
    crop_name: 'Soybean',
    ciclo_dias: 128,
    asset_owner_name: 'Pioneer',
    added: true,
    cultivar_id: 14445,
  },
  {
    id: 1186,
    safra_id: 8,
    semente_id: 1186,
    regiao_sojicola: '101',
    vcu: null,
    estado_id: null,
    recomendado: 1,
    ciclo: null,
    populacao_min: 250,
    populacao_max: 320,
    semeadura_ini: '2024-09-15',
    semeadura_fim: '2024-12-15',
    created_at: '2019-08-26 16:40:28',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: '95R90 IPRO',
    titular: null,
    grupo: '5.9',
    ogm: 'Sim',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 1,
    inox: 0,
    crop_cycle_id: null,
    growth_habit_id: 2,
    asset_owner_id: 136,
    crop_name: 'Soybean',
    ciclo_dias: 134,
    asset_owner_name: 'Pioneer',
    added: true,
    cultivar_id: 11536,
  },
  {
    id: 1187,
    safra_id: 8,
    semente_id: 1187,
    regiao_sojicola: '101',
    vcu: null,
    estado_id: null,
    recomendado: 1,
    ciclo: null,
    populacao_min: 250,
    populacao_max: 320,
    semeadura_ini: '2024-09-15',
    semeadura_fim: '2024-12-15',
    created_at: '2019-08-26 16:45:08',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: '95R95 IPRO',
    titular: null,
    grupo: '5.9',
    ogm: 'Sim',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 1,
    inox: 0,
    crop_cycle_id: null,
    growth_habit_id: 2,
    asset_owner_id: 136,
    crop_name: 'Soybean',
    ciclo_dias: 134,
    asset_owner_name: 'Pioneer',
  },
  {
    id: 1304,
    safra_id: 8,
    semente_id: 1304,
    regiao_sojicola: '101',
    vcu: null,
    estado_id: null,
    recomendado: 1,
    ciclo: null,
    populacao_min: 300,
    populacao_max: 340,
    semeadura_ini: '2024-09-15',
    semeadura_fim: '2024-12-31',
    created_at: '2020-11-03 11:54:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: '95Y02 IPRO',
    titular: null,
    grupo: '4.9',
    ogm: 'Sim',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 1,
    inox: 0,
    crop_cycle_id: null,
    growth_habit_id: 2,
    asset_owner_id: 136,
    crop_name: 'Soybean',
    ciclo_dias: 118,
    asset_owner_name: 'Pioneer',
    added: true,
    cultivar_id: 14174,
  },
]

const wheatVarieties = [
  {
    id: 1383,
    safra_id: 8,
    semente_id: 1383,
    regiao_sojicola: null,
    vcu: '1',
    estado_id: 21,
    recomendado: 1,
    ciclo: null,
    populacao_min: 330,
    populacao_max: 350,
    semeadura_ini: '2024-01-01',
    semeadura_fim: '2024-09-30',
    created_at: '2021-09-09 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: 'Ametista',
    titular: null,
    grupo: null,
    ogm: 'N\u00e3o',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 2,
    inox: 0,
    crop_cycle_id: 3,
    growth_habit_id: null,
    asset_owner_id: 131,
    crop_name: 'Wheat',
    ciclo_dias: 125,
    asset_owner_name: 'OR Melhoramento de Sementes',
  },
  {
    id: 1384,
    safra_id: 8,
    semente_id: 1384,
    regiao_sojicola: null,
    vcu: '1',
    estado_id: 21,
    recomendado: 1,
    ciclo: null,
    populacao_min: 360,
    populacao_max: 510,
    semeadura_ini: '2024-01-01',
    semeadura_fim: '2024-09-30',
    created_at: '2021-09-09 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: 'Anak',
    titular: null,
    grupo: null,
    ogm: 'N\u00e3o',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 2,
    inox: 0,
    crop_cycle_id: 2,
    growth_habit_id: null,
    asset_owner_id: 180,
    crop_name: 'Wheat',
    ciclo_dias: 124,
    asset_owner_name: 'Coodetec',
    added: true,
    cultivar_id: 14182,
  },
  {
    id: 2062,
    safra_id: 8,
    semente_id: 2062,
    regiao_sojicola: null,
    vcu: '1',
    estado_id: 21,
    recomendado: 1,
    ciclo: null,
    populacao_min: 250,
    populacao_max: 275,
    semeadura_ini: '2024-01-01',
    semeadura_fim: '2024-09-30',
    created_at: '2023-07-27 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: 'Biotrigo Sentinela',
    titular: null,
    grupo: null,
    ogm: 'N\u00e3o',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 2,
    inox: 0,
    crop_cycle_id: 6,
    growth_habit_id: null,
    asset_owner_id: 208,
    crop_name: 'Wheat',
    ciclo_dias: 148,
    asset_owner_name: 'Biotrigo Gen\u00e9tica',
  },
  {
    id: 2063,
    safra_id: 8,
    semente_id: 2063,
    regiao_sojicola: null,
    vcu: '1',
    estado_id: 21,
    recomendado: 1,
    ciclo: null,
    populacao_min: 330,
    populacao_max: 350,
    semeadura_ini: '2024-01-01',
    semeadura_fim: '2024-09-30',
    created_at: '2023-07-27 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: 'Biotrigo Talism\u00e3',
    titular: null,
    grupo: null,
    ogm: 'N\u00e3o',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 2,
    inox: 0,
    crop_cycle_id: 2,
    growth_habit_id: null,
    asset_owner_id: 208,
    crop_name: 'Wheat',
    ciclo_dias: 117,
    asset_owner_name: 'Biotrigo Gen\u00e9tica',
  },
  {
    id: 2061,
    safra_id: 8,
    semente_id: 2061,
    regiao_sojicola: null,
    vcu: '1',
    estado_id: 21,
    recomendado: 1,
    ciclo: null,
    populacao_min: 300,
    populacao_max: 330,
    semeadura_ini: '2024-01-01',
    semeadura_fim: '2024-09-30',
    created_at: '2023-07-27 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: 'Biotrigo Titan',
    titular: null,
    grupo: null,
    ogm: 'N\u00e3o',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 2,
    inox: 0,
    crop_cycle_id: 3,
    growth_habit_id: null,
    asset_owner_id: 208,
    crop_name: 'Wheat',
    ciclo_dias: 127,
    asset_owner_name: 'Biotrigo Gen\u00e9tica',
  },
  {
    id: 1387,
    safra_id: 8,
    semente_id: 1387,
    regiao_sojicola: null,
    vcu: '1',
    estado_id: 21,
    recomendado: 1,
    ciclo: null,
    populacao_min: 200,
    populacao_max: 300,
    semeadura_ini: '2024-01-01',
    semeadura_fim: '2024-09-30',
    created_at: '2021-09-09 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: 'BRS 179',
    titular: null,
    grupo: null,
    ogm: 'N\u00e3o',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 2,
    inox: 0,
    crop_cycle_id: 3,
    growth_habit_id: null,
    asset_owner_id: 194,
    crop_name: 'Wheat',
    ciclo_dias: 138,
    asset_owner_name: 'Embrapa',
  },
  {
    id: 1389,
    safra_id: 8,
    semente_id: 1389,
    regiao_sojicola: null,
    vcu: '1',
    estado_id: 21,
    recomendado: 1,
    ciclo: null,
    populacao_min: 200,
    populacao_max: 300,
    semeadura_ini: '2024-01-01',
    semeadura_fim: '2024-09-30',
    created_at: '2021-09-09 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: 'BRS 208',
    titular: null,
    grupo: null,
    ogm: 'N\u00e3o',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 2,
    inox: 0,
    crop_cycle_id: 3,
    growth_habit_id: null,
    asset_owner_id: 194,
    crop_name: 'Wheat',
    ciclo_dias: 124,
    asset_owner_name: 'Embrapa',
  },
  {
    id: 1394,
    safra_id: 8,
    semente_id: 1394,
    regiao_sojicola: null,
    vcu: '1',
    estado_id: 21,
    recomendado: 1,
    ciclo: null,
    populacao_min: 300,
    populacao_max: 330,
    semeadura_ini: '2024-01-01',
    semeadura_fim: '2024-09-30',
    created_at: '2021-09-09 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: 'BRS 296',
    titular: null,
    grupo: null,
    ogm: 'N\u00e3o',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 2,
    inox: 0,
    crop_cycle_id: 3,
    growth_habit_id: null,
    asset_owner_id: 194,
    crop_name: 'Wheat',
    ciclo_dias: 132,
    asset_owner_name: 'Embrapa',
  },
  {
    id: 1395,
    safra_id: 8,
    semente_id: 1395,
    regiao_sojicola: null,
    vcu: '1',
    estado_id: 21,
    recomendado: 1,
    ciclo: null,
    populacao_min: 300,
    populacao_max: 330,
    semeadura_ini: '2024-01-01',
    semeadura_fim: '2024-09-30',
    created_at: '2021-09-09 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: 'BRS 327',
    titular: null,
    grupo: null,
    ogm: 'N\u00e3o',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 2,
    inox: 0,
    crop_cycle_id: 3,
    growth_habit_id: null,
    asset_owner_id: 194,
    crop_name: 'Wheat',
    ciclo_dias: 130,
    asset_owner_name: 'Embrapa',
  },
  {
    id: 1396,
    safra_id: 8,
    semente_id: 1396,
    regiao_sojicola: null,
    vcu: '1',
    estado_id: 21,
    recomendado: 1,
    ciclo: null,
    populacao_min: 300,
    populacao_max: 330,
    semeadura_ini: '2024-01-01',
    semeadura_fim: '2024-09-30',
    created_at: '2021-09-09 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: 'BRS 328',
    titular: null,
    grupo: null,
    ogm: 'N\u00e3o',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 2,
    inox: 0,
    crop_cycle_id: 3,
    growth_habit_id: null,
    asset_owner_id: 194,
    crop_name: 'Wheat',
    ciclo_dias: 132,
    asset_owner_name: 'Embrapa',
  },
  {
    id: 1397,
    safra_id: 8,
    semente_id: 1397,
    regiao_sojicola: null,
    vcu: '1',
    estado_id: 21,
    recomendado: 1,
    ciclo: null,
    populacao_min: 330,
    populacao_max: 330,
    semeadura_ini: '2024-01-01',
    semeadura_fim: '2024-09-30',
    created_at: '2021-09-09 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: 'BRS 331',
    titular: null,
    grupo: null,
    ogm: 'N\u00e3o',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 2,
    inox: 0,
    crop_cycle_id: 3,
    growth_habit_id: null,
    asset_owner_id: 194,
    crop_name: 'Wheat',
    ciclo_dias: 126,
    asset_owner_name: 'Embrapa',
  },
  {
    id: 1398,
    safra_id: 8,
    semente_id: 1398,
    regiao_sojicola: null,
    vcu: '1',
    estado_id: 21,
    recomendado: 1,
    ciclo: null,
    populacao_min: 330,
    populacao_max: 330,
    semeadura_ini: '2024-01-01',
    semeadura_fim: '2024-09-30',
    created_at: '2021-09-09 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: 'BRS 374',
    titular: null,
    grupo: null,
    ogm: 'N\u00e3o',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 2,
    inox: 0,
    crop_cycle_id: 3,
    growth_habit_id: null,
    asset_owner_id: 194,
    crop_name: 'Wheat',
    ciclo_dias: 126,
    asset_owner_name: 'Embrapa',
  },
  {
    id: 1402,
    safra_id: 8,
    semente_id: 1402,
    regiao_sojicola: null,
    vcu: '1',
    estado_id: 21,
    recomendado: 1,
    ciclo: null,
    populacao_min: 300,
    populacao_max: 330,
    semeadura_ini: '2024-01-01',
    semeadura_fim: '2024-09-30',
    created_at: '2021-09-09 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: 'BRS Belajoia',
    titular: null,
    grupo: null,
    ogm: 'N\u00e3o',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 2,
    inox: 0,
    crop_cycle_id: 3,
    growth_habit_id: null,
    asset_owner_id: 194,
    crop_name: 'Wheat',
    ciclo_dias: 128,
    asset_owner_name: 'Embrapa',
  },
  {
    id: 1406,
    safra_id: 8,
    semente_id: 1406,
    regiao_sojicola: null,
    vcu: '1',
    estado_id: 21,
    recomendado: 1,
    ciclo: null,
    populacao_min: 300,
    populacao_max: 350,
    semeadura_ini: '2024-01-01',
    semeadura_fim: '2024-09-30',
    created_at: '2021-09-09 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: 'BRS Guabiju',
    titular: null,
    grupo: null,
    ogm: 'N\u00e3o',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 2,
    inox: 0,
    crop_cycle_id: 3,
    growth_habit_id: null,
    asset_owner_id: 194,
    crop_name: 'Wheat',
    ciclo_dias: 131,
    asset_owner_name: 'Embrapa',
  },
  {
    id: 1407,
    safra_id: 8,
    semente_id: 1407,
    regiao_sojicola: null,
    vcu: '1',
    estado_id: 21,
    recomendado: 1,
    ciclo: null,
    populacao_min: 300,
    populacao_max: 350,
    semeadura_ini: '2024-01-01',
    semeadura_fim: '2024-09-30',
    created_at: '2021-09-09 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: 'BRS Guamirim',
    titular: null,
    grupo: null,
    ogm: 'N\u00e3o',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 2,
    inox: 0,
    crop_cycle_id: 2,
    growth_habit_id: null,
    asset_owner_id: 194,
    crop_name: 'Wheat',
    ciclo_dias: 120,
    asset_owner_name: 'Embrapa',
  },
  {
    id: 1408,
    safra_id: 8,
    semente_id: 1408,
    regiao_sojicola: null,
    vcu: '1',
    estado_id: 21,
    recomendado: 1,
    ciclo: null,
    populacao_min: 300,
    populacao_max: 350,
    semeadura_ini: '2024-01-01',
    semeadura_fim: '2024-09-30',
    created_at: '2021-09-09 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: 'BRS Guaraim',
    titular: null,
    grupo: null,
    ogm: 'N\u00e3o',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 2,
    inox: 0,
    crop_cycle_id: 3,
    growth_habit_id: null,
    asset_owner_id: 194,
    crop_name: 'Wheat',
    ciclo_dias: 126,
    asset_owner_name: 'Embrapa',
  },
  {
    id: 1409,
    safra_id: 8,
    semente_id: 1409,
    regiao_sojicola: null,
    vcu: '1',
    estado_id: 21,
    recomendado: 1,
    ciclo: null,
    populacao_min: 300,
    populacao_max: 350,
    semeadura_ini: '2024-01-01',
    semeadura_fim: '2024-09-30',
    created_at: '2021-09-09 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: 'BRS Louro',
    titular: null,
    grupo: null,
    ogm: 'N\u00e3o',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 2,
    inox: 0,
    crop_cycle_id: 3,
    growth_habit_id: null,
    asset_owner_id: 194,
    crop_name: 'Wheat',
    ciclo_dias: 128,
    asset_owner_name: 'Embrapa',
    added: true,
    cultivar_id: 14212,
  },
  {
    id: 1410,
    safra_id: 8,
    semente_id: 1410,
    regiao_sojicola: null,
    vcu: '1',
    estado_id: 21,
    recomendado: 1,
    ciclo: null,
    populacao_min: 300,
    populacao_max: 330,
    semeadura_ini: '2024-01-01',
    semeadura_fim: '2024-09-30',
    created_at: '2021-09-09 00:00:00',
    updated_at: '2024-03-19 18:49:30',
    deleted_at: null,
    cultivar: 'BRS Marcante',
    titular: null,
    grupo: null,
    ogm: 'N\u00e3o',
    habito: null,
    fcc: null,
    icv: null,
    ativo: null,
    pais_id: 1,
    crop_id: 2,
    inox: 0,
    crop_cycle_id: 3,
    growth_habit_id: null,
    asset_owner_id: 194,
    crop_name: 'Wheat',
    ciclo_dias: 133,
    asset_owner_name: 'Embrapa',
  },
]

export { soybeanVarieties, wheatVarieties }
