<template>
  <v-row justify="center">
    <v-dialog
      v-model="isOpen"
      max-width="600px"
      hide-overlay
      scrollable
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar>
          <v-toolbar-title class="modal-text-action pl-4">{{
            $t('account.release_area')
          }}</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn class="mr-2" icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row no-gutters>
            <v-card-title class="modal-items">
              {{ $t('fazenda.fazendas') }} & {{ $t('talhoes.talhoes') }}
            </v-card-title>
            <v-col cols="12" v-for="farm in farms" :key="farm.id">
              <v-row no-gutters align-self="center" class="pb-2">
                <v-col cols="12" sm="7">
                  <v-card-title class="farm-name">
                    {{ farm.name }}
                  </v-card-title>
                </v-col>
                <v-col cols="12" sm="2" align-self="center">
                  <v-icon small>mdi-vector-square</v-icon>
                  <span class="used-area">{{ farm.total_area }}</span>
                </v-col>
                <v-col cols="12" sm="1" align-self="center">
                  <v-switch
                    class="custom-switch"
                    v-model="farm.enabled"
                    inset
                    hide-details
                    :loading="saving"
                    :disabled="saving"
                    @change="farmEnableDisable(farm)"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="2" align-self="center" class="text-status">
                  {{ statusFarmDescription(farm.enabled) }}
                </v-col>
              </v-row>

              <template v-if="plots.length">
                <v-row
                  class="mt-n4"
                  v-for="(plot, i) in getPlotsFarm(farm.id)"
                  no-gutters
                  :key="i"
                >
                  <v-col cols="12" sm="7">
                    <v-card-subtitle class="plot-name"
                      >{{ plot.name }}
                    </v-card-subtitle>
                  </v-col>
                  <v-col cols="12" sm="2" align-self="center">
                    <v-icon small>mdi-vector-square</v-icon>
                    <span class="used-area">{{ plot.area }}</span>
                  </v-col>
                  <v-col cols="12" sm="1" align-self="center">
                    <v-switch
                      class="custom-switch"
                      v-model="plot.enabled"
                      inset
                      hide-details
                      :loading="saving"
                      :disabled="saving"
                      @change="plotEnableDisable(plot)"
                    ></v-switch>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                    align-self="center"
                    class="text-status"
                  >
                    {{ statusPlotDescription(plot.enabled) }}
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-row no-gutters>
          <v-col cols="12" class="mt-2 pb-2">
            <v-row
              no-gutters
              align-self="center"
              class="distance-enabled-contracted"
            >
              <v-col cols="12" sm="5">
                <v-card-title class="footer-text">
                  {{ $t('total_area') }}
                </v-card-title>
              </v-col>
              <v-col cols="12" sm="3" align-self="center">
                <span class="footer-text">{{ $t('utilized') }}</span
                ><span class="footer-number" :class="colorUtilizedArea">
                  {{ formattedEnabledArea }} </span
                ><span>ha</span>
              </v-col>
              <v-col cols="12" sm="4" align-self="center">
                <span class="footer-text"
                  >&nbsp;&nbsp;&nbsp;{{ $t('contracted') }}</span
                >
                <span class="footer-number"
                  >&nbsp;&nbsp;{{
                    contractedArea.toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }}&nbsp;ha</span
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <action-dialog
        :dialog="dialogEnablePlot"
        :text="$t('fazenda.enable.warning', [countEnabledAsc])"
        :text-cancel="$t('no')"
        :text-confirm="$t('yes')"
        :cancel="closeEnablePlotConfirmation"
        :confirm="plotEnable"
        color-cancel="#8f8f8f"
        color-confirm="#39af49"
      />

      <action-dialog
        :dialog="dialogEnableFarm"
        :text="$t('fazenda.enable.warning', [countEnabledAsc])"
        :text-cancel="$t('no')"
        :text-confirm="$t('yes')"
        :cancel="closeEnableFarmConfirmation"
        :confirm="farmEnable"
        color-cancel="#8f8f8f"
        color-confirm="#39af49"
      />

      <action-dialog
        :dialog="dialogEnablePlotExceeded"
        :text="$t('fazenda.enable.error')"
        :text-cancel="$t('cancelar')"
        :text-confirm="$t('get_in_touch')"
        :cancel="closeDialogEnablePlotExceededConfirmation"
        :confirm="getInTouch"
        color-cancel="#8f8f8f"
        color-confirm="#39af49"
      />

      <action-dialog
        :dialog="dialogEnableFarmExceeded"
        :text="$t('fazenda.enable.error')"
        :text-cancel="$t('cancelar')"
        :text-confirm="$t('get_in_touch')"
        :cancel="closeDialogEnableFarmExceededConfirmation"
        :confirm="getInTouch"
        color-cancel="#8f8f8f"
        color-confirm="#39af49"
      />

      <action-dialog
        :dialog="dialogDisableFarm"
        :text="$t('fazenda.disable.warning')"
        :text-cancel="$t('no')"
        :text-confirm="$t('yes')"
        :cancel="closeDisableFarmConfirmation"
        :confirm="farmEnable"
        color-cancel="#8f8f8f"
        color-confirm="#39af49"
      />

      <action-dialog
        :dialog="dialogDisablePlot"
        :text="$t('fazenda.disable.warning')"
        :text-cancel="$t('no')"
        :text-confirm="$t('yes')"
        :cancel="closeDisablePlotConfirmation"
        :confirm="plotEnable"
        color-cancel="#8f8f8f"
        color-confirm="#39af49"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import ActionDialog from '@/components/Dialogs/ActionConfirmationDialog.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      isOpen: false,
      loading: false,
      saving: false,
      countEnabled: null,
      enabled: null,
      seasonAccount: null,
      dialogEnablePlot: false,
      dialogDisablePlot: false,
      dialogEnablePlotExceeded: false,
      dialogEnableFarmExceeded: false,
      dialogEnableFarm: false,
      dialogDisableFarm: false,
      plots: [
        {
          id: null,
          name: '',
          farm_id: null,
          enabled: false,
        },
      ],
    }
  },

  components: {
    ActionDialog,
  },

  async created() {
    this.isOpen = true
    await this.getPlots()
    await this.getSeasonAccount()
  },

  computed: {
    ...mapGetters('farms', ['farms']),
    ...mapGetters('user', ['currentUser']),
    contractedArea() {
      return parseFloat(this.currentUser?.total_area_account)
    },
    accountId() {
      return this.currentUser?.account?.id
    },
    colorUtilizedArea() {
      return this.calculateAreaEnabled >
        parseFloat(this.currentUser?.total_area_account || 0)
        ? 'exceeded-area'
        : 'within-limit'
    },
    accountName() {
      return this.currentUser?.name
    },
    countEnabledAsc() {
      return this.countEnabled == 0 ? 1 : this.countEnabled + 1
    },
    contactWhatsapp() {
      return (
        process.env.VUE_APP_RELATIONSHIP_WHATSAPP +
        this.$t('contato.msg_whatsapp_limit_enable_exceeded', [
          this.accountName,
        ])
      )
    },
    calculateAreaEnabled() {
      return this.plots
        .filter((plot) => plot.enabled)
        .reduce((acc, plot) => parseFloat(acc) + parseFloat(plot.area), 0)
    },
    formattedEnabledArea() {
      return parseFloat(this.calculateAreaEnabled).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    },
  },

  methods: {
    ...mapActions('farms', ['enableDisableFarm', 'firstFarmLoad']),
    getInTouch() {
      window.open(this.contactWhatsapp).focus()
      this.dialogEnablePlotExceeded = false
      this.dialogEnableFarmExceeded = false
    },
    async getPlots() {
      const response = await this.$api.talhoes.getAllByAccount(this.accountId)
      this.plots = response.data
    },
    async getSeasonAccount() {
      const response = await this.$api.seasonAccount?.get()
      this.seasonAccount = await response.data
      this.countEnabled = this.seasonAccount?.count_enabled
    },
    getPlotsFarm(farmId) {
      return this.plots?.filter((plot) => plot.farm_id === farmId)
    },
    statusFarmDescription(enabled) {
      return enabled ? this.$t('she_enabled') : this.$t('she_disabled')
    },
    statusPlotDescription(enabled) {
      return enabled ? this.$t('he_enabled') : this.$t('he_disabled')
    },
    close() {
      this.isOpen = false
      this.$emit('close')
    },
    async farmEnableDisable(farm) {
      if (farm.enabled) {
        this.showEnableDisableFarmConfirmation(farm.id)
      } else {
        this.showDisableFarmConfirmation(farm.id)
      }

      this.setEnableAndCountDisableFarm(farm.id)
      this.getSeasonAccount()

      if (this.countEnabled >= 2 && this.enabled) {
        this.showDialogEnableFarmExceededConfirmation()
      }
    },
    setEnableAndCountDisableFarm(farmId) {
      const farm = this.farms?.find((farm) => farm.id === farmId)

      this.enabled = farm.enabled
    },
    async farmEnable(farmId) {
      this.saving = true
      this.loading = true

      let defineFarmId = farmId ? farmId : this.farmId

      this.setEnableAndCountDisableFarm(defineFarmId)

      await this.enableDisableFarm({
        farmId: defineFarmId,
      })
        .then(async () => {
          await this.getSeasonAccount()

          if (!this.enabled) {
            this.$root.$emit(
              'notify',
              'success',
              this.$t('fazenda.disable.success')
            )
            this.saving = false
            this.loading = false
            this.closeEnableFarmConfirmation()
            this.closeDisableFarmConfirmation()
            return
          }

          this.$root.$emit(
            'notify',
            'success',
            this.$t('fazenda.enable.success')
          )

          this.closeEnableFarmConfirmation()
          this.closeDisableFarmConfirmation()
        })
        .catch(() => {
          this.closeDisableFarmConfirmation()
        })
        .finally(() => {
          this.loading = false
          this.saving = false
        })
    },
    closeEnableFarmConfirmation() {
      this.firstFarmLoad()
      this.getPlots()
      this.dialogEnableFarm = false
      this.farmId = null
    },
    closeDisableFarmConfirmation() {
      this.firstFarmLoad()
      this.getPlots()
      this.dialogDisableFarm = false
      this.farmId = null
    },
    showDisableFarmConfirmation(farmId) {
      this.farmId = farmId
      this.dialogDisableFarm = true
    },
    showEnableDisableFarmConfirmation(farmId) {
      this.farmId = farmId
      this.dialogEnableFarm = true
    },
    showDialogEnableFarmExceededConfirmation() {
      this.dialogEnableFarm = false
      this.dialogEnableFarmExceeded = true
    },
    closeDialogEnableFarmExceededConfirmation() {
      this.dialogEnableFarm = false
      this.dialogEnableFarmExceeded = false
    },
    async plotEnableDisable(plot) {
      if (plot.enabled) {
        this.showEnableDisablePlotConfirmation(plot.id)
      } else {
        this.showDisablePlotConfirmation(plot.id)
      }

      this.setEnableAndCountDisablePlot(plot.id)
      await this.getSeasonAccount()

      if (this.countEnabled >= 2 && this.enabled) {
        this.showDialogEnablePlotExceededConfirmation()
      }
    },
    async plotEnable() {
      this.saving = true
      this.loading = true

      this.plotId

      this.setEnableAndCountDisablePlot(this.plotId)

      await this.$api.talhoes
        .enableDisable(this.plotId)
        .then(async () => {
          await this.getSeasonAccount()

          if (!this.enabled) {
            this.$root.$emit(
              'notify',
              'success',
              this.$t('talhoes.disable.success')
            )
            this.saving = false
            this.loading = false
            this.closeEnablePlotConfirmation()
            this.closeDisablePlotConfirmation()
            this.$emit('refresh')
            return
          }

          this.$root.$emit(
            'notify',
            'success',
            this.$t('talhoes.enable.success')
          )

          this.closeEnablePlotConfirmation()
          this.closeDisablePlotConfirmation()
          this.$emit('refresh')
        })
        .catch(() => {
          this.closeDisablePlotConfirmation()
        })
        .finally(() => {
          this.loading = false
          this.saving = false
        })
    },
    setEnableAndCountDisablePlot(plotId) {
      const plot = this.plots?.find((plot) => plot.id === plotId)
      this.getSeasonAccount()

      this.enabled = plot?.enabled
    },
    closeEnablePlotConfirmation() {
      this.getPlots()
      this.dialogEnablePlot = false
      this.plotId = null
    },
    closeDisablePlotConfirmation() {
      this.getPlots()
      this.dialogDisablePlot = false
      this.plotId = null
    },
    showDisablePlotConfirmation(plotId) {
      this.plotId = plotId
      this.dialogDisablePlot = true
    },
    showEnableDisablePlotConfirmation(plotId) {
      this.plotId = plotId
      this.dialogEnablePlot = true
    },
    showDialogEnablePlotExceededConfirmation() {
      this.dialogEnablePlot = false
      this.dialogEnablePlotExceeded = true
    },
    closeDialogEnablePlotExceededConfirmation() {
      this.dialogEnablePlot = false
      this.dialogEnablePlotExceeded = false
    },
  },
}
</script>

<style scoped>
::v-deep.theme--light.v-list {
  background: #f5f5f5 !important;
}

::v-deep .v-card__text {
  background: #f5f5f5 !important;
}

::v-deep .v-input--selection-controls {
  margin-top: 0 !important;
}

::v-deep .v-input--switch__track {
  border-radius: 14px !important;
  height: 26px !important;
  background: #aab2a9 !important;
  opacity: 1 !important;
}

::v-deep .v-input--is-label-active > div > div > div > .v-input--switch__track {
  background: #4a76bc !important;
}

::v-deep .v-input--switch__thumb.primary--text {
  background: #fff !important;
  left: 1px !important;
  top: 2px !important;
}

::v-deep .custom-switch.v-input__slot .v-label {
  left: 13px !important;
}

::v-deep .v-input--selection-controls__ripple {
  height: 0;
  width: 0;
}

::v-deep .v-input--switch__thumb {
  width: 19px !important;
  height: 19px !important;
}

.modal-text-action {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 17px;
}

.modal-items {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
}

.farm-name {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: black;
}

.plot-name {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #788476;
}

.text-status {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  padding-left: 5px;
}

.used-area {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding-left: 13px;
}

.area-calculation {
  width: 120px;
  height: 40px;
  background-color: #f5f5f5;
  float: right;
  position: relative;
}

.footer-row {
  padding-right: 30px;
  padding-left: 23px;
  margin-top: -25px;
}

.footer-text {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  margin-left: -3px;
}

.footer-number {
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: #5b6459;
}

.distance-enabled-contracted {
  padding: 0px 15px;
}

.exceeded-area {
  color: #f23528;
}
.within-limit {
  color: #39af49;
}
</style>
