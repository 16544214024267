<script>
// eslint-disable-next-line no-unused-vars
const google_geometry =
  'https://maps.googleapis.com/maps/api/js?key=' +
  process.env.VUE_APP_GOOGLE_MAPS_KEY +
  '&libraries=places,drawing,geometry'
</script>

<script type="text/javascript" src="google_geometry"></script>

<script>
import { normalizeCoordinatesKML } from '@/utils/formatters'
import { mapActions } from 'vuex'

export default {
  name: 'ImportPlotsKmlCard',

  props: {
    fazenda: {
      type: Object,
    },
  },

  data() {
    return {
      polygonNames: [],
      polygonLatLon: [],
    }
  },

  methods: {
    ...mapActions('plot', ['createPlotByKMLFile']),
    importKml(event) {
      let reader = new FileReader()
      const file = event.target.files[0]
      this.$refs.hiddenInputFile.value = null
      reader.readAsText(file)

      reader.onload = () => {
        this.polygonFileToArray(reader.result)
      }
    },
    polygonFileToArray(file) {
      this.polygonNames = []
      this.polygonLatLon = []

      let parser = new DOMParser()
      let xmlDoc = parser.parseFromString(file, 'text/xml')
      let tag = xmlDoc.getElementsByTagName('Placemark')
      const polygonWarnings = []

      for (let i = 0; i < tag.length; i++) {
        let name = `Talhão ${i}`

        if (tag[i].getElementsByTagName('name').length > 0) {
          name = tag[i].getElementsByTagName('name')[0].textContent
        }
        const coordinates = tag[i].getElementsByTagName('coordinates')[0]

        let sanitizedCoordinates = []
        const coordinatesValues = coordinates.textContent
        const coordinatesAxis = coordinatesValues.split(' ')[0].split(',')
        // KML with X, Y and Z axis
        if (coordinatesAxis.length == 3) {
          sanitizedCoordinates = normalizeCoordinatesKML(coordinatesValues)
        } else {
          // KML with X and Y axis
          sanitizedCoordinates = coordinatesValues.split(/[\s,]+/)
        }

        if (sanitizedCoordinates.length <= 2) {
          polygonWarnings.push({
            status: 'warning',
            message: this.$t('talhoes.warnings.insufficient_coordinates', [
              name,
            ]),
          })
          continue
        }

        this.polygonNames.push([name])
        this.polygonLatLon.push(sanitizedCoordinates)
      }

      if (polygonWarnings.length) {
        return this.$root.$emit('notifyArray', polygonWarnings)
      } else if (
        this.polygonNames.length > 0 &&
        this.polygonLatLon.length > 0
      ) {
        this.polygonSave()
      } else {
        this.$root.$emit(
          'notify',
          'warning',
          this.$t('talhoes.warnings.insufficient_polygons')
        )
      }
    },
    async polygonSave() {
      const data = {
        plots: [],
        totalArea: 0,
      }
      data.plots = this.mountPlots()
      data.totalArea = data.plots.reduce((acc, { area }) => acc + area, 0)

      try {
        this.$emit('loading', true)
        if (!data.plots.length) {
          this.$root.$emit(
            'notify',
            'warning',
            this.$t('talhoes.salvar.warning')
          )
        }
        await this.createPlotByKMLFile(data)
        this.$root.$emit('notify', 'success', this.$t('talhoes.salvar.sucesso'))
        this.$emit('loading', false)
        this.$emit('criou')
      } catch (e) {
        this.$emit('loading', false)
      }
    },
    mountPlots() {
      const polygonWarnings = []
      const polygonInfos = []
      for (let index = 0; index < this.polygonNames.length; index++) {
        const { latitudes, longitudes } = this.getLatitudeAndLongitudes(
          this.polygonLatLon[index]
        )
        const name = this.polygonNames[index][0]

        if (latitudes.length < 2) {
          polygonWarnings.push({
            status: 'warning',
            message: this.$t('talhoes.warnings.no_latitude', [name]),
          })
          continue
        } else if (longitudes.length < 2) {
          polygonWarnings.push({
            status: 'warning',
            message: this.$t('talhoes.warnings.no_longitude', [name]),
          })
          continue
        }

        polygonInfos.push({
          coordenadas_lat: latitudes.join('|'),
          coordenadas_lng: longitudes.join('|'),
          area: this.calculateArea(latitudes, longitudes),
          nome: name,
          latitude: latitudes[0],
          longitude: longitudes[0],
          fazenda_id: this.fazenda.id,
        })
      }
      if (polygonWarnings.length) {
        this.$root.$emit('notifyArray', polygonWarnings)
        return []
      }
      return polygonInfos
    },
    getLatitudeAndLongitudes(sanitizedCoordinates) {
      const isEven = (i) => i % 2 == 0
      const isOdd = (i) => i % 2 == 1

      const longitudes = sanitizedCoordinates.filter((_, i) => isEven(i))
      const latitudes = sanitizedCoordinates.filter((_, i) => isOdd(i))

      return { latitudes, longitudes }
    },
    calculateArea(latitudes, longitudes) {
      const paths = latitudes.map((lat, index) => {
        return new google.maps.LatLng(lat, longitudes[index])
      })

      const polygonPath = new google.maps.Polygon({ paths }).getPath()
      const areaInMeters =
        google.maps.geometry.spherical.computeArea(polygonPath)

      const areaInHa = (areaInMeters / 10000).toFixed(2)
      return parseFloat(areaInHa)
    },
    open() {
      this.$refs.hiddenInputFile.click()
    },
  },
}
</script>

<template>
  <div>
    <slot name="btn-upload" :fazenda="fazenda" :open="open"></slot>
    <input
      name="upload"
      class="hidden-input-file"
      type="file"
      ref="hiddenInputFile"
      @change="importKml"
    />

    <!--forca inicializacao do componente (sem ocorre erro no carregamento)-->
    <gmap-map
      :center="{ lat: -8.06, lng: -34.87 }"
      style="width: 1px; height: 1px"
    >
    </gmap-map>
  </div>
</template>

<style>
.hidden-input-file {
  width: 0px;
  height: 0px;
  overflow: hidden;
}
</style>
