<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template #activator="{ on }">
        <div v-on="on" style="height: 100% !important; width: 100% !important">
          <slot></slot>
        </div>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="ma-2">
            <v-icon>mdi-map</v-icon>
            {{ $t('talhoes.cadastro') }}
          </v-toolbar-title>
          <div v-if="tutorial_toggle">
            <modal-video :url-video="urlAjuda" />
          </div>
          <v-spacer></v-spacer>
          <v-toolbar-items class="mt-4">
            <div class="mt-2">
              {{ $t('total') }} : {{ totalArea }}
              {{ $unitMeasures['area'][$currentLanguage()] }}
            </div>
          </v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn text @click="close" class="ma-2">
            {{ $t('feito') }}
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>

        <loader v-if="loading" />

        <v-item-group v-else>
          <v-container>
            <v-row justify="center" align="start">
              <v-col cols="12" md="6" justify="center" align="center">
                <v-item style="align: center">
                  <v-card class="center" height="300px">
                    <v-card-text>
                      <div class="headline font-weight-grey menu-criar-talhao">
                        {{ $t('talhoes.text1') }}
                      </div>
                      <br />
                      <div
                        class="subtitle-1 font-weight-grey menu-criar-talhao-text"
                      >
                        {{ $t('talhoes.text2') }}
                      </div>
                      <br />
                      <div
                        class="subtitle-2 font-weight-grey menu-criar-talhao-text"
                      >
                        {{ $t('talhoes.text3') }}
                      </div>
                    </v-card-text>

                    <v-row justify="center" align="center" dense>
                      <v-col></v-col>
                      <v-col cols="5">
                        <import-plots-kml-card
                          :fazenda="farm"
                          @criou="reloadPlots"
                          @loading="setLoadingKML"
                        >
                          <template #btn-upload="{ open }">
                            <v-btn
                              class="white--text blue-grey"
                              :loading="loadingKML"
                              @click="open"
                              >{{ $t('talhoes.text4') }}</v-btn
                            >
                          </template>
                        </import-plots-kml-card>
                      </v-col>

                      <v-col v-if="hasPermission($p.plot.create)"
                        >{{ $t('talhoes.text5') }}
                      </v-col>

                      <v-col cols="5" v-if="hasPermission($p.plot.create)">
                        <v-btn class="green white--text" @click="addPlot">{{
                          $t('adicionar')
                        }}</v-btn>
                      </v-col>
                      <v-col></v-col>
                    </v-row>
                  </v-card>
                </v-item>
              </v-col>

              <v-col
                v-for="talhao in plots"
                :key="talhao.id"
                cols="12"
                md="3"
                justify="center"
                align="center"
              >
                <v-item style="align: center">
                  <plot-card
                    v-if="accountsPlotsArea >= 0"
                    :talhao="talhao"
                    :plots="plots"
                    :farm="farm"
                    :accounts-plots-area="accountsPlotsArea"
                    @refresh="reloadPlots"
                  ></plot-card>
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
      </v-card>
    </v-dialog>

    <dialog-confirmation
      v-if="showConfirmationDialog"
      :dialog="showConfirmationDialog"
      :title="$t('talhoes.criar_pergunta')"
      :text="$t('talhoes.text_criar')"
      @agree="reloadPlots"
      @disagree="closeConfirmationDialog"
    />

    <plot-register
      v-if="plotRegisterOpen && newPlot && accountsPlotsArea >= 0"
      :plot="newPlot"
      :plots="plots"
      :accounts-plots-area="accountsPlotsArea"
      @refresh="reloadPlots"
    ></plot-register>
  </v-row>
</template>

<script>
import DialogConfirmation from '@/pages/fazendas/DialogConfirmation.vue'
import ImportPlotsKmlCard from '@/pages/fazendas/ImportPlotsKmlCard.vue'
import PlotCard from '@/pages/fazendas/ModalTalhoesItem.vue'
import ModalVideo from '@/components/ModalVideo.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import PlotRegister from '@/pages/fazendas/ModalMaps.vue'
import Loader from '@/components/Loaders/Loader.vue'
import { mapActions, mapGetters } from 'vuex'
import { convertToTargetUnit } from '@/components/Form/converter'

export default {
  name: 'ModalTalhoes',

  mixins: [PermissionMixin],

  components: {
    DialogConfirmation,
    PlotCard,
    ImportPlotsKmlCard,
    ModalVideo,
    PlotRegister,
    Loader,
  },

  data() {
    return {
      loadingKML: false,
      loading: false,
      isVisible: false,
      urlAjuda: 'https://www.youtube.com/embed/W_GCgA7yYiQ',
      accountsPlotsArea: null,
      plotRegisterOpen: false,
      showConfirmationDialog: false,
      totalArea: 0,
    }
  },

  props: {
    farm: {
      required: true,
      type: Object,
    },
  },

  async created() {
    this.isVisible = true
    this.loading = true
    const params = {
      farmId: this.farm?.id,
      params: 'enabled:desc,created_at:desc',
    }
    await this.getPlots(params)
    await this.reloadPlots()
  },

  watch: {
    loading(value) {
      if (!value) {
        const sum = (accumulator, t) => accumulator + parseFloat(t.area)
        let total = []
        if (this.plots?.length) {
          total = this.plots.filter((plot) => plot.enabled)
        }
        this.totalArea = convertToTargetUnit(total.reduce(sum, 0), 'area')
      }
    },
  },

  computed: {
    ...mapGetters('user', ['isUSA']),
    ...mapGetters('plot', ['plots']),
    initialPoint() {
      return this.plots.length > 0 ? this.plots[0] : this.farm?.location
    },
    newPlot() {
      if (!this.initialPoint?.latitude || !this.initialPoint?.longitude) {
        return null
      }

      return {
        id: null,
        municipio: this.farm?.location?.city?.name,
        fazenda_id: this.farm?.id,
        latitude: this.initialPoint?.latitude,
        longitude: this.initialPoint?.longitude,
        nome: '',
        coordenadas_lat: '',
        coordenadas_lng: '',
        area: 0,
      }
    },
    tutorial_toggle() {
      return process.env.VUE_APP_TUTORIAL_ENABLED === 'true' && !this.isUSA
    },
  },

  methods: {
    ...mapActions('plot', ['getPlots']),
    async getAccountsPlots() {
      const response = await this.$api.talhoes.getByAccount()
      this.accountsPlotsArea = Number(response?.data) ?? null
    },
    async reloadPlots() {
      this.loading = true
      this.closeConfirmationDialog()
      this.plotRegisterOpen = false
      await this.getAccountsPlots()
      this.loading = false
    },
    closeConfirmationDialog() {
      this.showConfirmationDialog = false
    },
    close() {
      this.$emit('close-modal')
      this.$emit('reload-farms-list')
      this.isVisible = false
    },
    setLoadingKML(val) {
      this.loadingKML = val
    },
    addPlot() {
      this.plotRegisterOpen = true
    },
  },
}
</script>

<style scoped>
.v-icon .notranslate .mdi .mdi-dots-vertical {
  color: grey;
}

.menu-criar-talhao {
  font-size: 20px;
}

.menu-criar-talhao-text {
  font-size: 12px;
}
</style>
